module.exports = {
  model: { id: '', name: '', vehicles: [] },
  schema: {
    fields: [
      {
        type: 'custom-input',
        options: { uppercase: true },
        label: 'Nome do Agrupamento',
        model: 'name',
        placeholder: 'Informe o nome do agrupamento',
        required: true,
      },
      {
        type: 'vueMultiSelect',
        label: 'Veículos',
        model: 'vehicles',
        required: false,
        multiSelect: true,
        placeholder: 'Selecione os veículos',
        selectOptions: {
          multiple: true,
          trackBy: 'id',
          label: 'plate',
          searchable: true,
          taggable: true,
          closeOnSelect: false,
          hideSelected: true,
        },
        values: [],
      },
    ],
  },
  columns: [{ key: 'name', label: 'nome do agrupamento', filter: true }],
  viewDataTypes: [
    { key: 'name', label: 'Nome do Agrupamento' },
    {
      key: 'vehicles',
      label: 'Veículos',
      multiple: true,
      array: true,
      keys: [{ key: 'plate', label: 'Placa' }],
    },
  ],
}
