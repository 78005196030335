<template>
  <div class="home">
    <custom-header
      type="vehicle-groups"
      title="Agrupamento de Veículos"
      labelButton="Novo Agrupamento"
      @create="openModal"
    ></custom-header>
    <custom-table
      :columns="columns"
      :options="true"
      type="vehicle-groups"
      @update="openModal"
      @view="view"
      @delete="this.delete"
      :rows="this.allVehicleGroups"
    ></custom-table>
    <modal
      v-if="isModalVisible"
      @close="closeModal"
      @submit="save()"
      :schema="schema"
      :model="model"
    ></modal>
    <view-data
      :viewData="viewData"
      :viewDataTypes="viewDataTypes"
      viewName="Agrupamento de veículo"
    ></view-data>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/vehicle-groups/store/service'
import VehicleGroup from '../models/vehicle-group'

export default {
  name: 'VehicleGroups',
  data() {
    return {
      columns: VehicleGroup.columns,
      model: VehicleGroup.model,
      viewDataTypes: VehicleGroup.viewDataTypes,
      schema: VehicleGroup.schema,
      viewData: {},
      isModalVisible: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('vehicleGroups', ['allVehicleGroups']),
    ...mapState('vehicles', ['allVehicles']),
  },
  methods: {
    ...mapActions('vehicleGroups', ['getAllVehicleGroups']),
    ...mapActions('vehicles', ['getAllVehicles']),
    async init() {
      await this.getAllVehicleGroups()
      await this.getAllVehicles()

      console.log(this.allVehicles)

      this.schema.fields[
        this.schema.fields.findIndex(x => x.model === 'vehicles')
      ].values = this.allVehicles
    },
    openModal(item) {
      if (item) {
        this.model = { ...item }
      }

      this.showModal()
    },
    view(item) {
      this.viewData = {
        ...item,
      }
      this.$modal.show('view')
    },
    async save() {
      const vehicleGroup = { ...this.model }

      console.log(vehicleGroup.vehicles)

      if (
        vehicleGroup.vehicles.filter(
          x =>
            (vehicleGroup.id &&
              !x.vehicle_group.find(y => y.id === vehicleGroup.id)) ||
            (!vehicleGroup.id && x.vehicle_group.length > 0)
        ).length > 0
      ) {
        const result = await this.$ask(
          'Veículo(s) selecionado(s) existe(m) em outro(s) grupo(s)'
        )
        if (!result.isConfirmed) return
      }

      if (vehicleGroup.vehicles) {
        vehicleGroup.vehicles = vehicleGroup.vehicles.map(x => {
          return x.id
        })
      }

      try {
        const save = vehicleGroup.id
          ? service.updateVehicleGroup(vehicleGroup)
          : await service.saveVehicleGroup(vehicleGroup)
        await this.$success('Grupo de Veículos')
        location.reload(true)
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    async delete({ id }) {
      try {
        await service.deleteVehicleGroup(id)
        await this.$delete('Grupo de Veículos')
        location.reload(true)
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.model = {}
    },
  },
}
</script>

<style scoped></style>
